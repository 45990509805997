/* eslint-disable i18next/no-literal-string */
import React from 'react'
import { NextPage } from 'next'
import Head from 'next/head'
import { StreamLogo } from 'components/common/Logo'

const ComingSoon: NextPage = () => (
  <>
    <Head>
      <title>Coming soon</title>
    </Head>
    <div className="bg-slate-100">
      <div className="m-auto flex min-h-screen w-full max-w-full flex-col items-center justify-center gap-10 p-5 sm:max-w-[480px]">
        <div className="text-center">
          <StreamLogo variant="primary" />
          <p className="text-[24px] font-normal">Portal</p>
        </div>
        <h1 className="mb-2 text-5xl font-semibold">Coming soon</h1>
      </div>
    </div>
  </>
)

export default ComingSoon
